import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These are the blogs and sites I've chosen for my `}<a parentName="p" {...{
        "href": "/web/humanizing-the-web"
      }}>{`human-curated web experience`}</a>{` (in no particular order).`}</p>
    <h2 {...{
      "id": "web"
    }}>{`Web`}</h2>
    <h3 {...{
      "id": "general"
    }}>{`General`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adactio.com/"
        }}>{`Jeremy Keith`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.aaron-gustafson.com/notebook/"
        }}>{`Aaron Gustafson`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.sarasoueidan.com/blog/"
        }}>{`Sara Soueidan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bradfrost.com/blog/"
        }}>{`Brad Frost`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://danmall.me/articles/"
        }}>{`Dan Mall`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://daverupert.com/"
        }}>{`Dave Rupert`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/"
        }}>{`CSS-Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tympanus.net/codrops/"
        }}>{`Codrops`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alistapart.com/"
        }}>{`A List Apart`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ethanmarcotte.com/wrote/"
        }}>{`Ethan Marcotte`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.zeldman.com/"
        }}>{`Zeldman`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.susanjeanrobertson.com/"
        }}>{`Susan Jean Robertson`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jeffgeerling.com/blog"
        }}>{`Jeff Geerling`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://shoptalkshow.com/"
        }}>{`ShopTalk Show`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rachelandrew.co.uk/archives"
        }}>{`Rachel Andrew`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://24ways.org/"
        }}>{`24 Ways`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://indieweb.org/"
        }}>{`This Week in the IndieWeb`}</a></li>
    </ul>
    <h3 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://davidakennedy.com/blog/"
        }}>{`David A. Kennedy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://a11yweekly.com/"
        }}>{`A11yWeekly`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.heydonworks.com/"
        }}>{`Heydon Pickering`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://inclusive-components.design"
        }}>{`Inclusive Components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://robdodson.me/"
        }}>{`Rob Dodson`}</a></li>
    </ul>
    <h3 {...{
      "id": "javascript"
    }}>{`Javascript`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gomakethings.com/articles/"
        }}>{`Chris Ferdinandi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://wesbos.com/blog/"
        }}>{`Wes Bos`}</a></li>
    </ul>
    <h2 {...{
      "id": "fun"
    }}>{`Fun`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://webcomicname.com/"
        }}>{`webcomic name`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      